// extracted by mini-css-extract-plugin
export var root = "Textarea-module--root--3Z9gX";
export var wrapper = "Textarea-module--wrapper--2xd0N";
export var input = "Textarea-module--input--3zycj";
export var label = "Textarea-module--label--2Dyex";
export var errorContainer = "Textarea-module--errorContainer--175It";
export var errorText = "Textarea-module--errorText--2kFU-";
export var focused = "Textarea-module--focused--2AHfM";
export var error = "Textarea-module--error--3Pf54";
export var light = "Textarea-module--light--xj64l";
export var dark = "Textarea-module--dark--38mc7";