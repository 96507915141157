import * as React from 'react';
import { useEffect } from 'react';
import { PageProps, navigate } from 'gatsby';

import { BUSINESS_NAME_KEY, EMAIL_KEY, NAME_KEY } from '@utils/defaults';
import { isEmail } from '@utils/validators';
import { BaseLayout } from '@layouts/BaseLayout';
import { ContentBlock } from '@components/contacts/ContentBlock';

const getQueryVariables = (path: string) => {
  const query = path.substring(1);
  const vars = query.split('&');
  const result: {
    name: string | null
    email: string | null
    nameOfBu: string | null
  } = {
    name: null,
    email: null,
    nameOfBu: null,
  };
  for (let i = 0; i < vars.length; i++) {
    const inner = vars[i].split('=');
    if (inner[0] === 'name' || inner[0] === 'email' || inner[0] === 'nameOfBu') {
      result[inner[0]] = inner[1];
    }
  }
  return result;
};

const Contacts: React.FC<PageProps> = ({
  location,
}) => {
  useEffect(() => {
    const search = location.search;
    if (search) {
      const vars = getQueryVariables(search);
      const name = vars.name;
      const email = vars.email;
      const nameOfBu = vars.nameOfBu;
      const lsName = window.localStorage.getItem(NAME_KEY);
      const lsEmail = window.localStorage.getItem(EMAIL_KEY);
      const lsBusinessName = window.localStorage.getItem(BUSINESS_NAME_KEY);
      if (name && name !== lsName) {
        window.localStorage.setItem(NAME_KEY, name);
      }
      if (email && email !== lsEmail && isEmail(email)) {
        window.localStorage.setItem(EMAIL_KEY, email);
      }
      if (nameOfBu && nameOfBu !== lsBusinessName) {
        window.localStorage.setItem(BUSINESS_NAME_KEY, nameOfBu);
      }
      navigate(
        '/contacts/',
        { replace: true },
      );
    }
  }, [location.search]);

  return (
    <BaseLayout
      seo={{
        title: 'Hello! Tell me all about your idea | Ilona K',
        description: 'I help businesses and entrepreneurs with website copywriting, content creation, branding strategy while converting qualifying leads into customers.',
      }}
      hiddenHeader
    >
      <ContentBlock />
    </BaseLayout>
  );
};

export default Contacts;
