import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { Field, withTypes } from 'react-final-form';
import { FormApi } from 'final-form';
import createDecorator from 'final-form-focus';

import { BUSINESS_NAME_KEY, EMAIL_KEY, NAME_KEY } from '@utils/defaults';
import { composeValidators, required, validateEmail } from '@utils/validators';
import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';
import { Input } from '@components/ui/Input';
import { Textarea } from '@components/ui/Textarea';
import { Container } from '@components/common/Container';
import { Checkbox } from '@components/common/Checkbox';
import { AnimatedLine } from '@components/common/AnimatedLine';
import { LinkArrow } from '@components/common/LinkArrow';
import { Cat } from '@components/home/Contact/Cat';

import * as s from './ContentBlock.module.sass';

const focusOnErrors = createDecorator();

type FormValues = {
  email: string
  name: string
  nameOfBusiness: string
  businessWebsite: string
  monthlyRecurring: 'yes' | 'other'
  budget: '1500-2000' | '2000-3000' | 'other'
  service: 'web-site' | 'branding' | 'monthly' | 'complex'
  note?: string
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const ContentBlock: React.FC = () => {
  const { toggleCursorType } = useContext(CursorContext);
  const { Form } = withTypes<FormValues>();
  const { colorThemeMode } = useContext(ColorThemeContext);

  const [formSendingError, setFormSendingError] = useState('');
  const [isAnimate, setIsAnimate] = useState(false);
  const [prevFields, setPrevFields] = useState<{
    name: string | null
    email: string | null
    nameOfBusiness: string | null
  }>({
    name: null,
    email: null,
    nameOfBusiness: null,
  });

  useEffect(() => {
    const lsName = window.localStorage.getItem(NAME_KEY) ?? null;
    const lsEmail = window.localStorage.getItem(EMAIL_KEY) ?? null;
    const lsBusinessName = window.localStorage.getItem(BUSINESS_NAME_KEY) ?? null;

    setPrevFields({
      name: lsName,
      email: lsEmail,
      nameOfBusiness: lsBusinessName,
    });
  }, []);

  const onSubmit = async (
    values: FormValues,
    form: FormApi<FormValues>,
  ) => {

    const res = await fetch('/api/subscribe-telegram', {
      body: JSON.stringify({
        email: prevFields.email || values.email,
        isUpdate: true,
        NAME: prevFields.name || values.name,
        NAME_OF_BU: prevFields.nameOfBusiness || values.nameOfBusiness,
        BUSINESS_W: values.businessWebsite,
        MONTHLYREC: values.monthlyRecurring,
        BUDGET: values.budget,
        SERVICE: values.service,
        NOTE: values.note,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const { error } = await res.json();

    if (error) {
      console.log('error', error);
      setFormSendingError(`${error}`);
    } else {
      if (values.name) {
        window.localStorage.setItem(NAME_KEY, values.name);
      }
      if (values.email) {
        window.localStorage.setItem(EMAIL_KEY, values.email);
      }
      if (values.nameOfBusiness) {
        window.localStorage.setItem(BUSINESS_NAME_KEY, values.nameOfBusiness);
      }

      setIsAnimate(true);
      setTimeout(() => {
        setIsAnimate(false);

        setTimeout(() => setTimeout(form.restart), 1800);
      }, 4000);
    }
  };

  return (
    <div className={modeClass[colorThemeMode]}>
      <div className={s.backWrapper}>
        <Container className={s.backContainer}>
          <AnimatedLine duration={.4} delay={.4} fromStart />
          <Link
            to='/'
            className={s.back}
            onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
            onMouseLeave={() => toggleCursorType(CursorTypes.default)}
          >
            <span className={s.backArrows}>
              {'<<<'}
            </span>
            To home page
          </Link>
          <AnimatedLine duration={.4} delay={.4} fromStart align='right' className={s.mobile} />
        </Container>
        <AnimatedLine direction='horizontal' duration={1} delay={.2} fromStart verticalAlign='bottom' />
      </div>

      <div className={s.heading}>
        <Container className={s.headingContainer}>
          <AnimatedLine duration={.4} delay={1} fromStart />
          <h1>Tell me all about your idea!</h1>
          <AnimatedLine duration={.4} delay={1} fromStart align='right' className={s.mobile} />
        </Container>
        <AnimatedLine direction='horizontal' duration={1} delay={.8} fromStart verticalAlign='bottom' />
      </div>

      <Container>
        <AnimatedLine duration={1.2} delay={1.3} fromStart />
        <Form
          onSubmit={onSubmit}
          // @ts-ignore
          decorators={[focusOnErrors]}
          initialValues={{ monthlyRecurring: 'yes', budget: '1500-2000', service: 'web-site' }}
          render={({
            handleSubmit, submitting,
          }) => (
            <form className={s.form} onSubmit={handleSubmit}>
              <Cat isAnimate={isAnimate} className={s.cat} />
              <div className={s.inputs}>
                <div className={cx(s.blur, { [s.blurActive]: isAnimate })} />
                {!prevFields.name && (
                  <Field
                    name="name"
                    validate={required}
                  >
                    {({ input, meta }) => (
                      <Input
                        {...input}
                        id='name'
                        label='Name'
                        className={s.input}
                        onChange={(e) => {
                          setFormSendingError('');
                          input.onChange(e);
                        }}
                        error={(meta.touched && meta.error) || meta.submitError}
                        onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                        onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                      />
                    )}
                  </Field>
                )}
                {!prevFields.email && (
                  <Field
                    name="email"
                    validate={composeValidators(required, validateEmail)}
                  >
                    {({ input, meta }) => (
                      <Input
                        {...input}
                        id='email'
                        label='Email'
                        className={s.input}
                        onChange={(e) => {
                          setFormSendingError('');
                          input.onChange(e);
                        }}
                        error={(meta.touched && meta.error) || meta.submitError}
                        onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                        onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                      />
                    )}
                  </Field>
                )}
                {!prevFields.nameOfBusiness && (
                  <Field
                    name="nameOfBusiness"
                    validate={required}
                  >
                    {({ input, meta }) => (
                      <Input
                        {...input}
                        id='nameOfBusiness'
                        label='Name of business'
                        className={s.input}
                        onChange={(e) => {
                          setFormSendingError('');
                          input.onChange(e);
                        }}
                        error={(meta.touched && meta.error) || meta.submitError}
                        onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                        onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                      />
                    )}
                  </Field>
                )}

                <Field
                  name="businessWebsite"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      id='businessWebsite'
                      label='Business Website'
                      className={s.input}
                      onChange={(e) => {
                        setFormSendingError('');
                        input.onChange(e);
                      }}
                      error={(meta.touched && meta.error) || meta.submitError}
                      onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                      onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                    />
                  )}
                </Field>



                <div className={cx(s.checkbox)}>
                  <div className={s.checkboxLabel}>
                    If interested in monthly content creation - are you prepared to make a monthly recurring investment in your business?
                  </div>
                  <div className={s.variants}>
                    <Field
                      name="monthlyRecurring"
                      type="radio"
                      value="yes"
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='Yes'
                        />
                      )}
                    </Field>
                    <Field
                      name="monthlyRecurring"
                      type="radio"
                      value="other"
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='Other'
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={cx(s.checkbox)}>
                  <div className={s.checkboxLabel}>
                    What is your budget?
                  </div>
                  <div className={s.variants}>
                    <Field
                      name="budget"
                      type="radio"
                      value="1500-2000"
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='$1500-$2000'
                        />
                      )}
                    </Field>
                    <Field
                      name="budget"
                      type="radio"
                      value="2000-3000"
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='$2000-$3000'
                        />
                      )}
                    </Field>
                    <Field
                      name="budget"
                      type="radio"
                      value="other"
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='Other'
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={cx(s.checkbox)}>
                  <div className={s.checkboxLabel}>
                    What services are you looking for?
                  </div>
                  <div className={s.variants}>
                    <Field
                      name="service"
                      type="radio"
                      value='web-site'
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='Web-site copywriting'
                        />
                      )}
                    </Field>
                    <Field
                      name="service"
                      type="radio"
                      value='branding'
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='Branding & Position strategies'
                        />
                      )}
                    </Field>
                    <Field
                      name="service"
                      type="radio"
                      value='monthly'
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='Monthly content creation'
                        />
                      )}
                    </Field>
                    <Field
                      name="service"
                      type="radio"
                      value='complex'
                    >
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          className={s.checkboxVariant}
                          label='Complex solutions'
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <Field name="notes">
                  {({ input, meta }) => (
                    <Textarea
                      {...input}
                      id='notes'
                      label='Do you have any other additional notes/questions that you would like me to address?'
                      className={cx(s.input, s.textarea)}
                      onChange={(e) => {
                        setFormSendingError('');
                        input.onChange(e);
                      }}
                      error={(meta.touched && meta.error) || meta.submitError}
                      onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                      onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                    />
                  )}
                </Field>

              </div>
              <div className={s.buttonWrapper}>
                <button
                  type="submit"
                  className={s.arrowLink}
                  disabled={(!formSendingError && submitting) || isAnimate}
                  onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                  onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                >
                  {submitting ? 'Submitting' : 'Send'}
                  <LinkArrow className={s.arrow} />
                </button>
                <div className={s.privacyBlock}>
                  By clicking the button, you accept
                  <Link
                    to='/privacy-policy'
                    className={cx(s.arrowLink, s.privacy)}
                    onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                    onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                  >
                    the privacy policy
                    <LinkArrow className={s.arrow} />
                  </Link>
                </div>
              </div>
            </form>
          )}
        />
        <AnimatedLine duration={1.2} delay={1.3} fromStart align='right' className={s.mobile} />
      </Container>
    </div>
  );
};