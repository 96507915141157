// extracted by mini-css-extract-plugin
export var heading = "ContentBlock-module--heading--1ADY1";
export var backWrapper = "ContentBlock-module--backWrapper--xE41C";
export var backContainer = "ContentBlock-module--backContainer--3LnN2";
export var back = "ContentBlock-module--back--f-nYG";
export var backArrows = "ContentBlock-module--backArrows--mnqvb";
export var headingContainer = "ContentBlock-module--headingContainer--24sSa";
export var headingDescription = "ContentBlock-module--headingDescription--CZ0Jw";
export var blur = "ContentBlock-module--blur--JKAQY";
export var blurActive = "ContentBlock-module--blurActive--1qPE5";
export var cat = "ContentBlock-module--cat--Ge2B7";
export var form = "ContentBlock-module--form--1HlwX";
export var input = "ContentBlock-module--input--1NtsO";
export var inputs = "ContentBlock-module--inputs--3kM6W";
export var buttonWrapper = "ContentBlock-module--buttonWrapper--1s5XU";
export var privacyBlock = "ContentBlock-module--privacyBlock--19KGM";
export var arrowLink = "ContentBlock-module--arrowLink--3XLjv";
export var arrow = "ContentBlock-module--arrow--3Op7T";
export var privacy = "ContentBlock-module--privacy--3Ax3S";
export var checkbox = "ContentBlock-module--checkbox--3UlRO";
export var textarea = "ContentBlock-module--textarea--35shc";
export var checkboxLabel = "ContentBlock-module--checkboxLabel--KWOgY";
export var variants = "ContentBlock-module--variants--3iTqk";
export var checkboxVariant = "ContentBlock-module--checkboxVariant--3axs_";
export var mobile = "ContentBlock-module--mobile--66ze7";
export var light = "ContentBlock-module--light--3QI4h";
export var dark = "ContentBlock-module--dark--1a8MT";