import * as React from 'react';
import { useContext } from 'react';
import cx from 'classnames';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';

import * as s from './Checkbox.module.sass';

type CheckboxProps = {
  label: string
} & React.InputHTMLAttributes<HTMLInputElement>;

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  checked,
  className,
  ...props
}) => {
  const { toggleCursorType } = useContext(CursorContext);
  const { colorThemeMode } = useContext(ColorThemeContext);

  return (
    <label
      htmlFor={id}
      className={cx(s.root, modeClass[colorThemeMode], { [s.checked]: checked }, className)}
      onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
      onMouseLeave={() => toggleCursorType(CursorTypes.default)}
    >
      {label}
      <input
        className={s.input}
        id={id}
        checked={checked}
        {...props}
      />
    </label>
  );
};